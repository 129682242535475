import 'bootstrap/scss/bootstrap.scss';
import 'bootstrap-icons/font/bootstrap-icons.scss';
import './App.scss';
import React, { Suspense } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Spinner from '../components/spinner/spinner.component'
import { ToastContainer, Slide } from 'react-toastify';


const QuantifyPublic = React.lazy(() => import('./QuantifyPublic'));

const App = ({ user, menu }) => {

	return (
		<div className={menu.isOpen ? "drawerOpen h-100" : "drawerClose h-100"}>
			<ToastContainer position="top-right" autoClose={2000} hideProgressBar transition={Slide} />
			<Spinner />
			<Suspense fallback={<div>Loading...</div>} >
			<Switch>
				<Redirect exact from="/" to="/home" />
				<Route exact path="/(home|terms|privacy|demoregister)" name="public page" render={props => <QuantifyPublic {...props} />} />
			</Switch>
			</Suspense>
		</div>
	)

};

const mapStateToProps = (state) => (

	{ user: state.user, menu: state.menu }
);

export default connect(mapStateToProps, null)(App);

